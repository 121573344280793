import { setSelected } from "@/store/scene-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { TreeData } from "@faro-lotv/project-source";
import { Box } from "@mui/material";
import { ReactElement, cloneElement, useEffect, useRef, useState } from "react";
import { Tree, TreeApi } from "react-arborist";
import { useEvents } from "../events";
import { TreeNode } from "./project-tree-node";

export type ProjectTreeProps = {
  /** project elements */
  tree?: TreeData[];
};

/** @returns a simple tree to explore ielements */
export function ProjectTree({ tree }: ProjectTreeProps): JSX.Element | null {
  const treeRef = useRef<TreeApi<TreeData>>();
  const dispatch = useAppDispatch();
  const { select } = useEvents();

  useTypedEvent(select, (id: GUID) => {
    if (!treeRef.current) return;
    treeRef.current.select(id, { align: true, focus: true });
    dispatch(setSelected(id));
  });

  if (!tree) return null;

  return (
    <TreeWrapper>
      <Tree<TreeData>
        ref={treeRef}
        data={tree}
        width="100%"
        openByDefault={false}
        disableDrag
        disableDrop
        rowHeight={20}
        indent={2}
        disableMultiSelection
        onSelect={(nodes) => dispatch(setSelected(nodes.at(0)?.data.id))}
      >
        {TreeNode}
      </Tree>
    </TreeWrapper>
  );
}

type TreeWrapperProps = {
  children: ReactElement;
};

/**
 * @returns Wrapper component that makes sure that the height of the trees are adjusted on window resize
 */
function TreeWrapper({ children }: TreeWrapperProps): JSX.Element | null {
  const treeContainerRef = useRef<HTMLDivElement>(null);
  const [treeHeight, setTreeHeight] = useState<number>();

  /**
   * Update the height of the tree to take the whole parent height on resize
   */
  useEffect(() => {
    function onResize(): void {
      setTreeHeight(treeContainerRef.current?.offsetHeight);
    }

    if (!treeContainerRef.current) return;

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(treeContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const actualTreeHeight = treeHeight ?? treeContainerRef.current?.offsetHeight;

  return (
    <Box
      component="div"
      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
      ref={treeContainerRef}
    >
      {/* Take the passed children and inject the height props */}
      {cloneElement(children, {
        // when one tree becomes invisible, tree other tree's height may become 0. In this case, set the height as undefined
        height: actualTreeHeight === 0 ? undefined : actualTreeHeight,
      })}
    </Box>
  );
}
